:root {
    --primary-color: #f26649;
    --bg-primary-30: #f2654930;
    --bg-primary-50: #f2654950;
    --bg-primary-70: #f2654970;
    --primary-hover-color: #f26549e6;
    --secondary-color: #70c2b3;
    --state-focus-shadow: 0 0 0 3px rgb(from #f26649 r g b/25%);
}

.logo {
    width: 75px;
}

.logo-text {
    font-weight: bold;
}

.button-primary,
.button-outline-primary:hover {
    background-color: var(--primary-color) !important;
    color: #f5f5f5 !important;
}

.button-primary:hover {
    background-color: var(--primary-hover-color) !important;
}

.button-outline-primary {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.slider-container {
    position: relative;
    padding: 0;
}

.slider-labels {
    position: absolute;
    width: 100%;
    top: 12px;
    left: 0;
}

.slider-tick {
    position: relative;
    text-align: center;
    width: calc(100% / 10);
}

.slider-tick:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 1.2px;
    height: 6px;
    background: #5f5f5f;
}

input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: var(--primary-color);
    border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-moz-range-thumb:hover {
    box-shadow: 0 0 0 10px rgba(255, 85, 0, .1);
}

input[type="range"]:active::-webkit-slider-thumb,
input[type="range"]:active::-moz-range-thumb,
input[type="range"]:focus::-webkit-slider-thumb,
input[type="range"]:focus::-moz-range-thumb {
    box-shadow: 0 0 0 13px rgba(255, 85, 0, .2);
}

.rs-picker-toggle {
    border-radius: 3px !important;
}

.rs-picker-toggle:focus,
.rs-picker-toggle:hover,
.rs-btn:focus,
.rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
    box-shadow: var(--state-focus-shadow) !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    color: var(--primary-color) !important;
    background-color: var(--bg-primary-30) !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    background-color: var(--bg-primary-70) !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #7a7a7a !important;
}

.rs-input:focus,
.rs-input:hover:not(:disabled),
.rs-input-group:hover,
.rs-input-group:focus,
.rs-input-group:focus-within {
    border-color: var(--primary-color) !important;
}

.rs-date-range-input::selection {
    color: #fff !important;
    background-color: var(--primary-color) !important;
}

.rs-input-group {
    border-radius: 3px !important;
    color: var(--primary-color) !important;
}

.rs-picker-toolbar-ranges .rs-btn,
.rs-picker-toolbar-right .rs-btn {
    color: var(--primary-color) !important;
}

.rs-picker-toolbar-right .rs-btn {
    background-color: var(--primary-color) !important;
    color: #f5f5f5 !important;
}

.rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-content:focus {
    background-color: var(--bg-primary-30) !important;
    color: var(--primary-color) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px var(--primary-color) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: var(--primary-color) !important;
    color: #f5f5f5 !important;
}

/* .rs-calendar-table-cell-in-range .rs-calendar-table-cell-content span {
    background-color: transparent !important;
    color: var(--primary-color) !important;
} */

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
    --bs-offcanvas-width: 500px !important;
}

.link {
    cursor: pointer;
    color: #0B5ED7;
    text-decoration: underline;
}
.plot-container{
    max-width: 30vw;
}

.flash-message{
    min-width: 280px;
    position: absolute;
    top: 100px;
    right: 0;
    z-index: 999;
    opacity: 0;
    transform: translateX(100%);
    animation: slideInFromRight 0.5s ease-in-out forwards;
}
  
@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}